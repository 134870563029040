const $ = jQuery;
const nextArrow = '<svg><use xlink:href="/img/sprites/symbol/sprite.svg#right-arrow"></use></svg>';
const prevArrow = '<svg><use xlink:href="/img/sprites/symbol/sprite.svg#left-arrow"></use></svg>';

$(() => {
  initBanners();
  initShopSlider();
  initCalendarEvents();
  initMenu();
  initViewSelect();
  initSelect();
  initCustomScroll();
  initTabs();
  initFilters();
  initProductGallery();
  initCartMap();
  initModals();
  initRecovery();

  $('[data-fancybox]').fancybox({
    buttons: [
      "zoom",
      "close"
    ]
  });
});

const initBanners = () => {
  $('.banners-slider').slick({
    arrows: false,
    dots: true,
    slidesToShow: 1
  });
};

const initShopSlider = () => {
  $('.shop-slider').slick({
    arrows: true,
    dots: false,
    slidesToShow: 4,
    prevArrow: '<button class="prev">'+prevArrow+'</button>',
    nextArrow: '<button class="next">'+nextArrow+'</button>',
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });
};

const initCalendarEvents = () => {
  $('.calendar__day').click(function (e) {
    e.preventDefault();
    $(this).toggleClass('is-active').siblings().removeClass('is-active');
  });

  setCalendarEventsPosition();
  $(window).resize(setCalendarEventsPosition);
};

const setCalendarEventsPosition = () => {
  const w = $(window).width();
  $.each($('.event-popup'), function(k, v) {
    $(v).css('margin-left', '');
    const width  = $(v).outerWidth();
    const left = $(v).offset().left;

    if (left < 0) {
      $(v).css('margin-left', Math.abs(left) + 10);
    } else {
      const right = left + width;
      if (w < right) {
        $(v).css('margin-left', (w - right) - 10);
      }
    }
  });
};

const initMenu = () => {
  $('.menu__nav-next-level').click(function(e) {
    e.preventDefault();
    $(this).closest('.menu__nav').addClass('next-level-is-active');
    $(this).closest('li').addClass('is-active');
  });

  $('.menu__nav-prev-level').click(function(e) {
    e.preventDefault();
    $(this).closest('.menu__nav').removeClass('next-level-is-active');
    $(this).closest('.menu__nav-submenu').closest('li').removeClass('is-active');
  });

  $('.header__menu-btn').click(function(e) {
    e.preventDefault();
    $('.menu').addClass('is-active');
  });

  $('.menu__close').click(function(e) {
    e.preventDefault();
    $('.menu').removeClass('is-active');
  });
};

const initViewSelect = () => {
  $('.view-btn').click(function(e) {
    e.preventDefault();
    $(this).addClass('is-active').siblings().removeClass('is-active');

    const target = $(this).attr('data-view');
    $(target).addClass('is-active').siblings().removeClass('is-active');
  });
};

const initSelect = () => {
  $('.select__btn').click(function(e) {
    e.preventDefault();
    $(this).closest('.select').toggleClass('is-active');
  });

  $('.select__list li').click(function(e) {
    const text = $(this).text();
    $(this).closest('.select').removeClass('is-active');
    $(this).closest('.select').find('.select__btn span').text(text);
    $(this).addClass('is-active').siblings().removeClass('is-active');
  });

  $(document).click(function(e) {
    const target = e.target;
    const activeSelect = $('.select.is-active');

    if (activeSelect.length > 0) {
      if (!activeSelect[0].contains(target)) {
        activeSelect.removeClass('is-active');
      }
    }
  });
};

const initCustomScroll = () => {
  $('.horiz-custom-scroll').mCustomScrollbar({
    axis: "x",
    scrollButtons:{ enable: true }
  });
};

const initTabs = () => {
  $('.tabs__nav-button').click(function(e) {
    e.preventDefault();

    $(this).addClass('is-active').siblings().removeClass('is-active');
    $($(this).attr('href')).addClass('is-active').siblings().removeClass('is-active');
  });
};

const initFilters = () => {
  $('.shop-controls__open-filter').click(function(e) {
    e.preventDefault();
    $('.sidebar-filters').addClass('is-active');
  });

  $('.sidebar-filters__close').click(function(e) {
    e.preventDefault();
    $('.sidebar-filters').removeClass('is-active');
  });
};

const initProductGallery = () => {
  $('.product-gallery').slick({
    arrows: true,
    dots: false,
    slidesToShow: 1,
    prevArrow: '<button class="prev">'+prevArrow+'</button>',
    nextArrow: '<button class="next">'+nextArrow+'</button>',
    fade: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true
        }
      }
    ]
  }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    const navParent = $('.product-gallery-nav')[0];
    const navItem = $(`.product-gallery-nav__item:nth-child(${nextSlide+1})`);
    navItem.addClass('is-active').siblings().removeClass('is-active');
    scrollToViewport(navItem[0], navParent);
  });

  $('.product-gallery-nav__item').click(function(e) {
    e.preventDefault();
    $(this).addClass('is-active').siblings().removeClass('is-active');
    const index = $('.product-gallery-nav__item').index($(this));
    $('.product-gallery').slick('slickGoTo', parseInt(index));
  });
};

function scrollToViewport(child, parent) {
  const childLeft = child.getBoundingClientRect().left;
  const childWidth = child.offsetWidth;
  const parentLeft = parent.getBoundingClientRect().left;
  const parentWidth = parent.offsetWidth;
  let difference = 0;

  if (childLeft - 40 < parentLeft) {
    difference = childLeft - parentLeft - 50;
  } else if (childLeft + childWidth + 40 > parentLeft + parentWidth) {
    difference = childLeft + childWidth - parentLeft - parentWidth + 70;
  }

  if (difference !== 0) {
    parent.scrollBy({
      behavior: "smooth",
      top: 0,
      left: difference
    });
  }
}

const initCartMap = () => {
  $('.cart-show-map').click(function(e) {
    e.preventDefault();
    $('.cart-map').addClass('is-active');
  });

  $('.cart-map__close').click(function(e) {
    e.preventDefault();
    $('.cart-map').removeClass('is-active');
  });
};

const initModals = () => {
  $('.js-modal-open').click(function(e) {
    e.preventDefault();
    $($(this).attr('data-modal')).addClass('is-active');
  });

  $('.js-modal-close').click(function(e) {
    e.preventDefault();
    $(this).closest('.modal').removeClass('is-active');
  });
};

const initRecovery = () => {
  $('.service-modal__form').submit(function(e) {
    e.preventDefault();
    $(this).closest('.service-modal').addClass('show-success');
  });
};